import { format } from 'date-fns';
import {
  arrayRemove,
  arrayUnion,
  collection,
  collectionGroup,
  deleteField,
  DocumentReference,
  DocumentSnapshot,
  getCountFromServer,
  getDocs,
  getFirestore,
  increment,
  limit,
  orderBy,
  query,
  serverTimestamp,
  startAfter,
  startAt,
  Timestamp,
  where,
  writeBatch,
  WriteBatch,
} from 'firebase/firestore';
import { FirestoreQueryParams } from '@og-shared/types';

export function firestoreStartBatch() {
  return writeBatch(getFirestore());
}

export function firestoreEndBatch(batch: WriteBatch) {
  return batch.commit();
}

export function firestoreIncrement(number: number) {
  return increment(number) as unknown as number;
}

export function firestoreDeleteField() {
  return deleteField() as any;
}

export function createFirestoreTimestamp(date: Date) {
  return Timestamp.fromDate(date);
}

export function firestoreArrayUnion<T>(union: T[]) {
  return arrayUnion(...union) as unknown as T[];
}

export function firestoreArrayRemove<T>(remove: T) {
  return arrayRemove(remove) as unknown as T[];
}

export function firestoreServerTimestamp() {
  return serverTimestamp() as Timestamp;
}

export function formatDateFromFirestoreTimestamp(
  timestamp: Timestamp | null,
  formatString: string = 'MMM d, h:mm aa'
) {
  if (!timestamp || !timestamp.toDate) return '';
  return format(timestamp.toDate(), formatString);
}

export function formatDateWithYearFromFirestoreTimestamp(
  timestamp: Timestamp | null,
  formatString: string = 'MMM d, yyyy h:mm aa'
) {
  if (!timestamp || !timestamp.toDate) return '';
  return format(timestamp.toDate(), formatString);
}

export async function firestoreCollectionQueryGet<T>(params: {
  firestoreQuery: FirestoreQueryParams<T>;
  afterDoc?: DocumentSnapshot;
  atDoc?: DocumentSnapshot;
}) {
  const { firestoreQuery, afterDoc, atDoc } = params;
  const q = getFirestoreQuery({ firestoreQuery, afterDoc, atDoc });
  const querySnapshot = await getDocs(q);
  const docs = querySnapshot.docs.map(doc => doc.data() as T);
  const docPaths = querySnapshot.docs.map(d => d.ref.path);
  return {
    docs,
    lastDoc: querySnapshot.docs[docs.length - 1],
    hasMore: querySnapshot.docs.length === firestoreQuery.limit,
    docPaths,
  };
}

function getFirestoreQuery<T>(params: {
  firestoreQuery: FirestoreQueryParams<T>;
  afterDoc?: DocumentSnapshot;
  atDoc?: DocumentSnapshot;
}) {
  const {
    firestoreQuery: {
      queryType,
      collectionPath,
      orderByOp,
      whereOps,
      limit: docLimit,
    },
    afterDoc,
    atDoc,
  } = params;

  const collectionRef =
    queryType === 'collection'
      ? collection(getFirestore(), collectionPath)
      : collectionGroup(getFirestore(), collectionPath);

  let q = query(collectionRef);
  if (whereOps) {
    whereOps.map(op => {
      q = query(
        q,
        where(op.fieldPath as string, op.whereFilterOp, op.fieldValue)
      );
    });
  }
  if (orderByOp.fieldPath) {
    q = query(
      q,
      orderBy(orderByOp.fieldPath as string, orderByOp.orderByDirection)
    );
  }
  if (docLimit) {
    q = query(q, limit(docLimit));
  }
  if (afterDoc) {
    q = query(q, startAfter(afterDoc));
  }
  if (atDoc) {
    q = query(q, startAt(atDoc));
  }
  return q;
}

export async function firestoreCollectionCount<T>(params: {
  firestoreQuery: FirestoreQueryParams<T>;
}) {
  const { firestoreQuery } = params;
  const q = getFirestoreQuery({ firestoreQuery });
  const snapshot = await getCountFromServer(q);
  return snapshot.data().count;
}

export async function firestoreDeleteDocumentsInBatches(
  docs: DocumentReference[]
): Promise<any> {
  if (docs.length > 400) {
    const newBatch = writeBatch(getFirestore());
    docs.slice(0, 400).map(docRef => {
      newBatch.delete(docRef);
    });
    await newBatch.commit();
    return firestoreDeleteDocumentsInBatches(docs.slice(400));
  }
  const secondBatch = writeBatch(getFirestore());
  docs.map(docRef => {
    secondBatch.delete(docRef);
  });
  return secondBatch.commit();
}
